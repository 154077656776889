<script>
	import { useMeta } from "vue-meta";
	import Menu from "@/components/Tiger_25_Q1-2/Menu";

	export default {
		name: "Tiger25Q1-2_info",
		data() {
			return {};
		},
		setup() {
			useMeta({
				title: "虎牌啤酒，一起無所畏",
				description: "2025/3/5-4/1至指定通路，購買虎牌全系列啤酒任兩件，登錄發票抽高雄櫻花季周邊",
			});
		},
		components: {
			Menu,
		},
		computed: {},
		watch: {},
		mounted() {},
		methods: {},
	};
</script>
<template src="./template.pug" lang="pug"></template>
<style src="./style.sass" lang="sass" scoped></style>
